<template>
  <div class="ProgressSettingsMain">
    <div>
      <p class="pageSettingsTitle">Progress Bar</p>
    </div>

    <div class="mt-1 d-flex align-items-center justify-content-between">
      <p class="m-0 p-0 pageSettingHeader">Show bar</p>
      <div class="show-logo custom-control custom-switch cursor-pointer">
        <input
          type="checkbox"
          class="custom-control-input"
          :id="`progressBar-toggle-${Date.now()}`"
          v-model="progressBar.showProgressbar"
        />
        <label
          class="custom-control-label"
          :for="`progressBar-toggle-${Date.now()}`"
        ></label>
      </div>
    </div>

    <div class="mt-2">
      <!-- Lines Section Start -->
      <div class="LineContainer mb-3">
        <div class="d-flex align-items-center mb-2">
          <p class="m-0 p-0 pageSettingHeader">Lines</p>
        </div>

        <div class="LinesRadioSection">
          <!-- V1 Start -->
          <div class="d-flex align-items-center my-1">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev1"
                @change="updateProgressBarTypeAndVariant('line', 'v1')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="188" height="8" rx="4" fill="#FFA201" />
              </svg>
            </div>
          </div>
          <!-- v1 End -->
          <!-- V2 Start -->
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev2"
                @change="updateProgressBarTypeAndVariant('line', 'v2')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="110" height="8" rx="4" fill="#FFA201" />
                <circle cx="104" cy="6" r="6" fill="#FFA201" />
                <circle cx="104" cy="6" r="4" fill="white" />
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <circle cx="292" cy="6" r="4" fill="white" />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <circle cx="192" cy="6" r="4" fill="white" />
                <circle cx="6" cy="6" r="6" fill="#FFA201" />
                <circle cx="6" cy="6" r="4" fill="white" />
              </svg>
            </div>
          </div>
          <!-- v2 End -->
          <!-- V3 Start -->
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev3"
                @change="updateProgressBarTypeAndVariant('line', 'v3')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="110" height="8" rx="4" fill="#FFA201" />
                <circle cx="104" cy="6" r="6" fill="#FFA201" />
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M289.376 7.864V6.984L291.992 3.24H293.296V6.888H294V7.864H293.296V9H292.176V7.864H289.376ZM292.248 4.44L290.608 6.888H292.248V4.44Z"
                  fill="white"
                />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M189.44 4.704C189.467 4.17067 189.653 3.76 190 3.472C190.352 3.17867 190.813 3.032 191.384 3.032C191.773 3.032 192.107 3.10133 192.384 3.24C192.661 3.37333 192.869 3.55733 193.008 3.792C193.152 4.02133 193.224 4.28267 193.224 4.576C193.224 4.912 193.136 5.19733 192.96 5.432C192.789 5.66133 192.584 5.816 192.344 5.896V5.928C192.653 6.024 192.893 6.19467 193.064 6.44C193.24 6.68533 193.328 7 193.328 7.384C193.328 7.704 193.253 7.98933 193.104 8.24C192.96 8.49067 192.744 8.688 192.456 8.832C192.173 8.97067 191.832 9.04 191.432 9.04C190.829 9.04 190.339 8.888 189.96 8.584C189.581 8.28 189.381 7.832 189.36 7.24H190.448C190.459 7.50133 190.547 7.712 190.712 7.872C190.883 8.02667 191.115 8.104 191.408 8.104C191.68 8.104 191.888 8.02933 192.032 7.88C192.181 7.72533 192.256 7.528 192.256 7.288C192.256 6.968 192.155 6.73867 191.952 6.6C191.749 6.46133 191.435 6.392 191.008 6.392H190.776V5.472H191.008C191.765 5.472 192.144 5.21867 192.144 4.712C192.144 4.48267 192.075 4.304 191.936 4.176C191.803 4.048 191.608 3.984 191.352 3.984C191.101 3.984 190.907 4.05333 190.768 4.192C190.635 4.32533 190.557 4.496 190.536 4.704H189.44Z"
                  fill="white"
                />
                <circle cx="6" cy="6" r="6" fill="#FFA201" />
                <g clip-path="url(#clip0_1481_6711)">
                  <path
                    d="M4.99993 7.39001L3.60993 6.00001L3.1366 6.47001L4.99993 8.33334L8.99993 4.33334L8.52993 3.86334L4.99993 7.39001Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1481_6711">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(2 2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <!-- v3 End -->
        </div>
      </div>
      <!-- Lines Section End -->

      <!-- Dots Section Start -->
      <div class="DotsContainer mt-2 pb-3">
        <div class="d-flex align-items-center mb-2">
          <p class="m-0 p-0 pageSettingHeader">Dots</p>
        </div>

        <div class="DotsRadioSection">
          <!-- V1 Start -->
          <div class="d-flex align-items-center my-1">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="dotsv1"
                @change="updateProgressBarTypeAndVariant('dots', 'v1')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="293"
                height="8"
                viewBox="0 0 293 8"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="15" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="30" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="45" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="60" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="75" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="90" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="105" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="120" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="135" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="150" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="165" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="180" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="195" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="210" width="8" height="8" rx="4" fill="#FFA201" />
                <rect x="225" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="240" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="255" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="270" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="285" width="8" height="8" rx="4" fill="#BFBFBF" />
              </svg>
            </div>
          </div>
          <!-- v1 End -->
          <!-- V2 Start -->
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="dotsv2"
                @change="updateProgressBarTypeAndVariant('dots', 'v2')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="104" cy="6" r="6" fill="#FFA201" />
                <g clip-path="url(#clip0_1481_6746)">
                  <path
                    d="M103 7.39001L101.61 6.00001L101.137 6.47001L103 8.33334L107 4.33334L106.53 3.86334L103 7.39001Z"
                    fill="white"
                  />
                </g>
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M289.376 7.864V6.984L291.992 3.24H293.296V6.888H294V7.864H293.296V9H292.176V7.864H289.376ZM292.248 4.44L290.608 6.888H292.248V4.44Z"
                  fill="white"
                />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M189.44 4.704C189.467 4.17067 189.653 3.76 190 3.472C190.352 3.17867 190.813 3.032 191.384 3.032C191.773 3.032 192.107 3.10133 192.384 3.24C192.661 3.37333 192.869 3.55733 193.008 3.792C193.152 4.02133 193.224 4.28267 193.224 4.576C193.224 4.912 193.136 5.19733 192.96 5.432C192.789 5.66133 192.584 5.816 192.344 5.896V5.928C192.653 6.024 192.893 6.19467 193.064 6.44C193.24 6.68533 193.328 7 193.328 7.384C193.328 7.704 193.253 7.98933 193.104 8.24C192.96 8.49067 192.744 8.688 192.456 8.832C192.173 8.97067 191.832 9.04 191.432 9.04C190.829 9.04 190.339 8.888 189.96 8.584C189.581 8.28 189.381 7.832 189.36 7.24H190.448C190.459 7.50133 190.547 7.712 190.712 7.872C190.883 8.02667 191.115 8.104 191.408 8.104C191.68 8.104 191.888 8.02933 192.032 7.88C192.181 7.72533 192.256 7.528 192.256 7.288C192.256 6.968 192.155 6.73867 191.952 6.6C191.749 6.46133 191.435 6.392 191.008 6.392H190.776V5.472H191.008C191.765 5.472 192.144 5.21867 192.144 4.712C192.144 4.48267 192.075 4.304 191.936 4.176C191.803 4.048 191.608 3.984 191.352 3.984C191.101 3.984 190.907 4.05333 190.768 4.192C190.635 4.32533 190.557 4.496 190.536 4.704H189.44Z"
                  fill="white"
                />
                <circle cx="6" cy="6" r="6" fill="#FFA201" />
                <line
                  x1="12"
                  y1="6.75"
                  x2="98"
                  y2="6.75001"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <line
                  x1="110"
                  y1="6.75"
                  x2="186"
                  y2="6.74999"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <line
                  x1="198"
                  y1="6.75"
                  x2="286"
                  y2="6.74999"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <defs>
                  <clipPath id="clip0_1481_6746">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(100 2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <!-- v2 End -->
        </div>
      </div>
      <!-- Dots Section End -->
    </div>

    <div class="mt-2">
      <div class="d-flex align-items-center mb-2">
        <p class="m-0 p-0 pageSettingHeader">Bar Settings</p>
      </div>

      <div>
        <!-- ProgressBar Styles Start -->
        <div
          class="w-100 d-flex justify-content-between align-items-center mb-2"
        >
          <span class="pageSettingHeader">RTL direction </span>
          <span class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`directionRTL-${Date.now()}`"
              v-model="progressBar.RTLdirection"
            />
            <label
              class="custom-control-label"
              :for="`directionRTL-${Date.now()}`"
            ></label>
          </span>
        </div>

        <!-- Size Input -->
        <div class="form-group d-flex align-items-center w-100">
          <div class="d-flex align-items-center mr-3">
            <span class="pageSettingHeader"> Size:</span>
            <input
              type="number"
              min="1"
              max="20"
              v-model="progressBar.progressStyles.lineHeight"
              class="form-control-sm ml-3 input-border-style"
              style="
                background: transparent;
                color: #4d4950;
                border: 0.8px solid #d2d8e0;
              "
            />
          </div>
          <div
            class="d-flex align-items-center"
            v-if="progressBar.progressBarType == 'line'"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                fill="white"
              />
            </svg>

            <input
              type="number"
              v-model="progressBar.progressStyles.borderRadius"
              min="0"
              max="10"
              class=" ml-2  form-control-sm input-border-style"
              style="
                background: transparent;
                color: #4d4950;
                border: 0.8px solid #d2d8e0;
              "
            />
          </div>
        </div>
        <div>
          <!-- Progress Bar Color -->
          <div class="form-group d-flex mb-2 align-items-center row">
            <div class="col-12">
              <span class="pageSettingHeader">Progress color:</span>
            </div>
            <div class="col-8 d-flex align-items-center pr-0">
              <input
                type="color"
                v-model="progressBar.progressStyles.LineBgColor"
                style="
                  min-width: 30px;
                  width: 30px;
                  height: 30px;
                  background: transparent;
                "
                class="form-control-color form-control input-border-style"
              />
              <input type="text" class="ColorTextInput input-border-style ml-2 " style="width:90px;max-width: 90px;" v-model="progressBar.progressStyles.LineBgColor">
            </div>
            <div class="col-4 d-flex align-items-center px-0">
              <svg
                class="mr-2"
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                  fill="white"
                />
              </svg>

              <input
                type="number"
                min="0"
                max="100"
                v-model="progressBar.progressStyles.LineBgOpacity"
                class="form-control-sm  size-input input-border-style"
              />
            </div>
           
          </div>

          <!-- Controls Colors Input -->
          <div class="form-group d-flex mb-2 align-items-center row">
            <div class="col-12">
              <span class="pageSettingHeader">Controls color:</span>
            </div>
            <div class="col-8 d-flex align-items-center pr-0">
              <input
                type="color"
                v-model="progressBar.progressStyles.LineControlBg"
                style="
                  min-width: 30px;
                  width: 30px;
                  height: 30px;
                  background: transparent;
                "
                class="form-control-color form-control input-border-style"
              />
              <input type="text" class="ColorTextInput input-border-style ml-2" style="width:90px;max-width: 90px;" v-model="progressBar.progressStyles.LineControlBg">
            </div>
            <div class="col-4 d-flex align-items-center px-0">
              <svg
                class="mr-2"
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7163 5.66665L6.99967 0.958313L2.28301 5.66665C0.983008 6.96665 0.333008 8.69998 0.333008 10.3666C0.333008 12.0333 0.983008 13.7916 2.28301 15.0916C3.58301 16.3916 5.29134 17.05 6.99967 17.05C8.70801 17.05 10.4163 16.3916 11.7163 15.0916C13.0163 13.7916 13.6663 12.0333 13.6663 10.3666C13.6663 8.69998 13.0163 6.96665 11.7163 5.66665ZM1.99967 10.6666C2.00801 8.99998 2.51634 7.94165 3.46634 6.99998L6.99967 3.39165L10.533 7.04165C11.483 7.97498 11.9913 8.99998 11.9997 10.6666H1.99967Z"
                  fill="white"
                />
              </svg>

              <input
                type="number"
                min="1"
                max="100"
                v-model="progressBar.progressStyles.LineControlOpacity"
                class="form-control-sm  size-input input-border-style"
              />
            </div>
           
          </div>

          <!-- Question Text -->
          <div class="form-group my-3 subheading">
            <label for="content" class="pageSettingHeader">Text:</label>
            <input
              type="text"
              id="LineQuestionInput"
              v-model="progressBar.questionText"
              class="form-control-sm d-block input-border-style"
              style="
                width: 80%;
                background: transparent;
                color: #4d4950;
                border: 0.8px solid #d2d8e0;
              "
            />
          </div>

          <!-- Controls Colors Input -->
          <div class="form-group d-flex mb-2 align-items-center row">
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <span class="pageSettingHeader">Font:</span>
                </div>
                <div class="col-12">
                  <div class="">
                    <select
                      class="form-select w-100 form-control-sm  ml-1 input-border-style"
                      v-model="progressBar.progressStyles.fontFamily"
                    >
                      <option
                        v-for="font in getFontFamilyList"
                        :key="font.value"
                        :value="font.value"
                      >
                        {{ font.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <span class="pageSettingHeader">Text color:</span>
                </div>
                <div class="col-12 d-flex align-items-center pr-0">
                  <input
                    type="color"
                    v-model="progressBar.progressStyles.textColor"
                    style="
                      min-width: 30px;
                      width: 30px;
                      height: 30px;
                      background: transparent;
                    "
                    class="form-control-color form-control input-border-style"
                  />
                  <span> {{ progressBar.progressStyles.textColor }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Question Alignment -->
          <div class="form-group d-flex mb-2 align-items-center row">
            <div class="col-12">
              <span class="pageSettingHeader">Question align:</span>
            </div>
            <div class="col-12 mt-1">
              <span
                class="mx-2"
                type="button"
                :class="{
                  'active-svg':
                    progressBar.progressStyles.questionAlign === 'left',
                }"
                @click="progressBar.progressStyles.questionAlign = 'left'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <span
                class="mx-2"
                type="button"
                :class="{
                  'active-svg':
                    progressBar.progressStyles.questionAlign === 'center',
                }"
                @click="progressBar.progressStyles.questionAlign = 'center'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <span
                class="mx-2"
                type="button"
                :class="{
                  'active-svg':
                    progressBar.progressStyles.questionAlign === 'right',
                }"
                @click="progressBar.progressStyles.questionAlign = 'right'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      progressBar: {},
      progressView: "progressType",
      currentProgressType: "",
    };
  },
  computed: {
    ...mapGetters(["getProgressBar"]),
    currentProgressBarValue() {
      return this.getProgressBar;
    },
    
getFontFamilyList() {
return fontFamilyList;
},
progressStyleFont(){
      return this.progressBar.progressStyles.fontFamily
    },
  },
  watch: {
    progressBar: {
      deep: true,
      handler(newValue ,oldVal) {
        this.setProgressBar(this.progressBar);
        if(Object.keys(oldVal).length != 0 && newValue == oldVal ){
          this.setIsAnyPageEdited(true)
        }
      
      },
    },
    progressStyleFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  },
  methods: {
    ...mapMutations(["setProgressBar","setIsAnyPageEdited"]),
    checkProgressHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorProgressOutput(value) {
      let isHex = this.checkProgressHexCode(value);
      if (isHex) {
        return value;
      } else {
        return "Gradient input";
      }
    },
    checkControlHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorControlOutput(value) {
      let isHex = this.checkControlHexCode(value);
      if (isHex) {
        return value;
      } else {
        return "Gradient input";
      }
    },
    updateProgressBarTypeAndVariant(type, variant) {
      if (type && variant) {
        this.progressBar.progressBarType = type;
        this.progressBar.progressBarVariant = variant;
      }
    },
  },
  mounted() {
    if (this.currentProgressBarValue) {
      this.progressBar = this.currentProgressBarValue;
      this.currentProgressType =
        this.progressBar.progressBarType + this.progressBar.progressBarVariant;

      if (
        !Object.prototype.hasOwnProperty.call(
          this.progressBar.progressStyles,
          "fontFamily"
        )
      ) {
        this.progressBar.progressStyles.fontFamily = "Poppins";
      }
    }

    this.progressView = "progressType";
  },
};
</script>

<style scoped>
/* .ProgressSettingsMain {
  background: green;
} */

.versionImgContainer {
  border: 0.5px solid #d2d8e0;
  border-radius: 8px;
  padding: 5px 15px;
}

.CustomRadio >>> .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
